import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
// import Sketch from 'react-p5'
import { BrowserQRCodeReader } from '@zxing/library'

const codeReader = new BrowserQRCodeReader()

const QRScanner = ({ onScanned }) => {
  let video = useRef(null)
  const [videoInputDevices, setVideoInputDevices] = useState([])
  const [qrResult, setQrResult] = useState('')

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: false, video: true })
      .then(stream => {
        codeReader
          .listVideoInputDevices()
          .then(devices => {
            setVideoInputDevices(devices)
          })
          .catch(err => console.error(err))
      })
      .catch(err => console.error(err))
  }, [])

  useEffect(() => {
    console.log({ videoInputDevices })
    if (!videoInputDevices.length) return
    let firstDeviceId
    if (videoInputDevices.length > 1)
      firstDeviceId = videoInputDevices[1].deviceId
    else firstDeviceId = videoInputDevices[0].deviceId
    codeReader
      .decodeOnceFromVideoDevice(firstDeviceId, 'video')
      .then(result => setQrResult(result.text))
      .catch(err => console.error(err))
  }, [videoInputDevices])

  useEffect(() => {
    onScanned(qrResult)
  }, [onScanned, qrResult])

  return (
    <div
      style={{
        border: '1px solid gray',
        width: '100%',
        paddingTop: '75%',
        position: 'relative',
      }}
    >
      <video
        ref={video}
        id="video"
        width="300"
        height="200"
        style={{
          width: '100%',
          height: 'auto',
          top: 0,
          left: 0,
          position: 'absolute',
          objectFit: 'cover',
        }}
      ></video>
    </div>
  )
}

QRScanner.propTypes = {
  onScanned: PropTypes.func,
}
export default QRScanner
