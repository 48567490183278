import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import QRScanner from '../components/qr-scanner'
import { navigate } from 'gatsby'
import { Grid, Typography } from '@material-ui/core'

const SecondPage = () => {
  const scannedHandler = handle => {
    if (handle) navigate('/plant-profile/' + handle)
  }
  return (
    <Layout>
      <SEO title="QR Scanner" />
      <Grid
        container
        justify="center"
        alignContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography component="h1" variant="h4">
            Leafbook QR Scanner
          </Typography>
          <p>Scan the QR code on your plant using your camera.</p>
          <QRScanner onScanned={scannedHandler} />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default SecondPage
